import { ImageProps } from '@royalacademy/component-library';

export type ImageOptionsProps = {
  ratio: 'off' | '16:9' | '4:3' | '6:4' | '1:1';
  dimensions: ImageDimensionProps;
};

export type ImageDimensionProps = {
  small: ImageDimensionSetProps;
  small2x?: boolean;
  medium: ImageDimensionSetProps;
  medium2x?: boolean;
  large: ImageDimensionSetProps;
  large2x?: boolean;
  xl?: ImageDimensionSetProps;
  xl2x?: boolean;
};

export type ImageDimensionSetProps = {
  width: number;
  height?: number;
};

const getImages = (key: string, title: string, alt: string, options: ImageOptionsProps): ImageProps => {
  const imageHost = 'https://cloudinary.royalacademy.org.uk';
  const imgDpr = key.includes('png') ? 1 : 1.5;
  let c = 'c_limit';
  let ar = '';
  if (options.ratio != 'off') {
    ar = ',ar_' + options.ratio;
    c = 'c_fill';
  }

  const folder = '/royal-academy/image/upload/';
  const settings = ',cs_tinysrgb,dn_72,f_auto,fl_progressive.keep_iptc,';
  const prefix = `${imageHost}${folder}${c}${settings}`;
  const suffix = `${ar}/${key}`;

  const imageString = (width: number, height?: number, dpr?: boolean): string =>
    `${prefix}${dpr ? `dpr_${imgDpr},` : ''}w_${width}${height ? ',h_' + height : ''}${suffix}`;

  return {
    src: {
      small: imageString(options.dimensions.small.width, options.dimensions.small.height, false),
      ...(options.dimensions.small2x && {
        small2x: imageString(options.dimensions.small.width, options.dimensions.small.height, true),
      }),
      medium: imageString(options.dimensions.medium.width, options.dimensions.medium.height, false),
      ...(options.dimensions.medium2x && {
        medium2x: imageString(options.dimensions.medium.width, options.dimensions.medium.height, true),
      }),
      large: imageString(options.dimensions.large.width, options.dimensions.large.height, false),
      ...(options.dimensions.large2x && {
        large2x: imageString(options.dimensions.large.width, options.dimensions.large.height, true),
      }),
      ...(options.dimensions.xl && {
        xl: imageString(options.dimensions.xl.width, options.dimensions.xl.height, false),
      }),
      ...(options.dimensions.xl &&
        options.dimensions.xl2x && {
          xl2x: imageString(options.dimensions.xl.width, options.dimensions.xl.height, true),
        }),
    },
    altText: alt,
    title: title,
  };
};

export default getImages;
