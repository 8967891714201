import { useEffect, useState } from "react";
import { useTransformContext } from "./use-transform-context";
import { getState } from "utils";
export function useTransformComponent(callback) {
    var libraryContext = useTransformContext();
    var _a = useState(callback(getState(libraryContext))), transformRender = _a[0], setTransformRender = _a[1];
    useEffect(function () {
        var mounted = true;
        var unmount = libraryContext.onChange(function (ref) {
            if (mounted) {
                setTransformRender(callback(getState(ref.instance)));
            }
        });
        return function () {
            unmount();
            mounted = false;
        };
    }, [callback, libraryContext]);
    return transformRender;
}
