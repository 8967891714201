import React, { ReactElement, useState } from 'react';
import { GALinkProps } from '../../defaultProps';
import './image.scss';

export type ImageProps = {
  src: {
    small: string;
    small2x?: string;
    medium: string;
    medium2x?: string;
    large: string;
    large2x?: string;
    xl?: string;
    xl2x?: string;
  };
  altText?: string | null;
  title?: string | null;
  loadingAttr?: 'lazy' | 'eager' | undefined;
  gaTags?: GALinkProps | undefined;
  onLoad?: () => void;
};

export default function Image({ src, altText, title, loadingAttr = 'lazy', gaTags, onLoad }: ImageProps): ReactElement {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = (): void => {
    setIsLoaded(true);
    if (onLoad) onLoad();
  };

  return (
    <picture className={`image ${isLoaded ? 'image--loaded' : ''}`}>
      {src.xl2x && (
        <source
          srcSet={src.xl2x}
          media="(min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: 1024px) and (min-resolution: 120dpi)"
        />
      )}
      {src.xl && <source srcSet={src.xl} media="(min-width: 1024px)" />}
      {src.large2x && (
        <source
          srcSet={src.large2x}
          media="(min-width: 992px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: 768px) and (min-resolution: 120dpi)"
        />
      )}
      <source srcSet={src.large} media="(min-width: 768px)" />
      {src.medium2x && (
        <source
          srcSet={src.medium2x}
          media="(min-width: 480px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: 480px) and (min-resolution: 120dpi)"
        />
      )}
      <source srcSet={src.medium} media="(min-width: 480px)" />
      {src.small2x && (
        <source
          srcSet={src.small2x}
          media="(min-width: 320px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: 320px) and (min-resolution: 120dpi)"
        />
      )}
      <source srcSet={src.small} media="(min-width: 320px)" />
      <img
        src={src.small}
        alt={altText || ''}
        title={title || ''}
        loading={loadingAttr}
        data-ga4-type={gaTags?.type}
        data-ga4-area={gaTags?.area}
        data-gtm-name={gaTags?.name}
        onLoad={handleLoad}
      />
    </picture>
  );
}
