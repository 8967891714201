import { __assign } from "tslib";
/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useRef } from "react";
import { Context } from "../transform-wrapper/transform-wrapper";
import { baseClasses } from "../../constants/state.constants";
import styles from "./transform-component.module.css";
export var TransformComponent = function (_a) {
    var children = _a.children, _b = _a.wrapperClass, wrapperClass = _b === void 0 ? "" : _b, _c = _a.contentClass, contentClass = _c === void 0 ? "" : _c, wrapperStyle = _a.wrapperStyle, contentStyle = _a.contentStyle, _d = _a.wrapperProps, wrapperProps = _d === void 0 ? {} : _d, _e = _a.contentProps, contentProps = _e === void 0 ? {} : _e;
    var _f = useContext(Context), init = _f.init, cleanupWindowEvents = _f.cleanupWindowEvents;
    var wrapperRef = useRef(null);
    var contentRef = useRef(null);
    useEffect(function () {
        var wrapper = wrapperRef.current;
        var content = contentRef.current;
        if (wrapper !== null && content !== null && init) {
            init === null || init === void 0 ? void 0 : init(wrapper, content);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return function () {
            cleanupWindowEvents === null || cleanupWindowEvents === void 0 ? void 0 : cleanupWindowEvents();
        };
    }, []);
    return (React.createElement("div", __assign({}, wrapperProps, { ref: wrapperRef, className: "".concat(baseClasses.wrapperClass, " ").concat(styles.wrapper, " ").concat(wrapperClass), style: wrapperStyle }),
        React.createElement("div", __assign({}, contentProps, { ref: contentRef, className: "".concat(baseClasses.contentClass, " ").concat(styles.content, " ").concat(contentClass), style: contentStyle }), children)));
};
