import React, { ReactElement } from 'react';
import { GALinkProps } from '../../../defaultProps';
import { ImageProps } from '../../Image/Image';
import Image from '../../Image/Image';
import './eventCard.scss';
import { formatText } from '../../../util';
import PlaceholderImage from '../../Image/PlaceholderImage';

export type EventCardProps = {
  label?: string;
  status?: string;
  title: string;
  subtitle?: string;
  date?: string;
  time?: string;
  image?: ImageProps;
  free?: boolean;
  link: string;
  hasVideo?: boolean;
  gaTags?: GALinkProps;
  headingTag?: 'h2' | 'h3';
  ratio?: '16x9' | '4x3';
};

export default function EventCard({
  label,
  status,
  title,
  subtitle,
  date,
  image,
  time,
  free = false,
  link,
  hasVideo,
  gaTags,
  headingTag = 'h2',
  ratio
}: EventCardProps): ReactElement {
  const HeadingTag = headingTag;
  return (
    <div className={`event-card ${ratio ? `event-card--${ratio}` : ''}`}>
      <div className="event-card__container">
        <div className="event-card__image">
          {image ? <Image {...image} /> : <PlaceholderImage />}
          {label && <div className="event-card__label">{label}</div>}
          {free && <div className="event-card__free">Free</div>}
          {hasVideo && <div className="event-card__video" />}
        </div>
        <div className="event-card__content">
          <HeadingTag className="event-card__title">
            <a
              className="event-card__link"
              href={link}
              data-ga4-type={gaTags?.type}
              data-ga4-area={gaTags?.area}
              data-gtm-name={gaTags?.name}
            >
              {formatText(title)}
              <span className="link-hotspot"></span>
            </a>
          </HeadingTag>
          {subtitle && <h3 className="event-card__subtitle">{subtitle}</h3>}
          {date && <p className="event-card__date">{date}</p>}
          {time && <p className="event-card__time">{time}</p>}
          {status && <p className="event-card__status">{status}</p>}
        </div>
      </div>
    </div>
  );
}
