import { baseClasses } from "../constants/state.constants";
var matchPrefix = ".".concat(baseClasses.wrapperClass);
export var isExcludedNode = function (node, excluded) {
    return excluded.some(function (exclude) {
        return node.matches("".concat(matchPrefix, " ").concat(exclude, ", ").concat(matchPrefix, " .").concat(exclude, ", ").concat(matchPrefix, " ").concat(exclude, " *, ").concat(matchPrefix, " .").concat(exclude, " *"));
    });
};
export var cancelTimeout = function (timeout) {
    if (timeout) {
        clearTimeout(timeout);
    }
};
